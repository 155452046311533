import React from "react";
import styled from "styled-components";
import html2canvas from "html2canvas";
import FileSaver from "file-saver";
import {
  useGlobalPenToolContext,
  useGlobalPenToolActionsContext,
  useGlobalUndoContext,
  useGlobalRedoContext,
  useGlobalRemoteVolumeContext,
  useGlobalRemoteVolumeActionsContext,
  useGlobalResetContext,
  useGlobalDrawFnContext,
} from "../../../Context/GlobalContextProvider";
import Mic from "../assets/icons/mic.svg";
import MicOff from "../assets/icons/MIC_OFF.svg";
import Video from "../assets/icons/videocam.svg";
import VideoOff from "../assets/icons/videocam_off.svg";
import ScreenOff from "../assets/icons/Screenshare.svg";
import Screen from "../assets/icons/SCREENSHARE_OFF.svg";
import End from "../assets/icons/call_end.svg";
import ScreenViewPort from "../assets/icons/SCREEN_SIZEVIEW.svg";
import PenTool from "../assets/icons/edit.svg";
import PenToolColor from "../assets/icons/edit_color.svg";
import VolumeUp from "../assets/icons/volume_up.svg";
// import BrightnessLow from "../assets/icons/brightness_low.svg";
// import Palette from "../assets/icons/palette.svg";
// import LineWeight from "../assets/icons/line_weight.svg";
import Redo from "../assets/icons/undo.svg";
import Undo from "../assets/icons/redo.svg";
import Reset from "../assets/icons/update-24px.svg";
import CenterFocusStrong from "../assets/icons/center_focus_strong.svg";
// import Freehand from "../assets/icons/freehand.svg";

const Controls = ({
  handleCallDisconnect,
  handleAudioToggle,
  handleVideoToggle,
  handleFullscreenToggle,
  isFullscreen,
  audio,
  video,
  screen,
  handleScreenToggle,
}) => {
  const [penToolSubMenu, setPenToolSubMenu] = React.useState(false);
  const [showVolumeRange, setShowVolumeRange] = React.useState(false);
  const isPenTool = useGlobalPenToolContext();
  const setPenTool = useGlobalPenToolActionsContext();
  const remoteVolume = useGlobalRemoteVolumeContext();
  const setRemoteVolume = useGlobalRemoteVolumeActionsContext();
  const { undo } = useGlobalUndoContext();
  const { redo } = useGlobalRedoContext();
  const { reset } = useGlobalResetContext();
  const { init } = useGlobalDrawFnContext();

  const controlRef = React.useRef();

  const isSmall = window.screen.width <= 480;

  const showPenMenu = () => setPenToolSubMenu(!penToolSubMenu);

  function captureVideos() {
    let canvas = document.getElementById("canvas"); // declare a canvas element in your html
    let ctx = canvas.getContext("2d");
    ctx.scale(-1, 1);
    let videos = document.querySelectorAll("video");
    let w, h;

    for (let i = 0, len = videos.length; i < len; i++) {
      const v = videos[i];
      console.log(v.srcObject);
      if (!v.srcObject) continue; // no video here
      try {
        w = v.videoWidth;
        h = v.videoHeight;
        canvas.width = w;
        canvas.height = h;
        ctx.fillRect(0, 0, w, h);
        ctx.drawImage(v, 0, 0, w, h);
        const a = canvas.toDataURL();
        v.style.backgroundImage = `url(${a})`;
        v.style.backgroundSize = "cover";
        ctx.clearRect(0, 0, w, h); // clean the canvas
      } catch (e) {
        console.log(e);
        continue;
      }
    }
  }

  React.useEffect(() => {
    let timeout;
    if (isFullscreen) {
      timeout = setTimeout(() => {
        if(controlRef.current) controlRef.current.style.bottom = "-99px";
      }, 2000);
    } else {
      if(controlRef.current) controlRef.current.style.bottom = "0";
    }

    return () => { clearTimeout(timeout) }
  }, [isFullscreen]);

  const showControl = () => {
    if (isFullscreen) {
      console.log("Opening Control");
      if(controlRef.current) controlRef.current.style.bottom = "0";
    }
  };

  const hideControl = () => {
    if (isFullscreen) {
      console.log("Hiding Control");
      if(controlRef.current) controlRef.current.style.bottom = "-99px";
    }
  };

  return (
    <>
      <div
        className="control"
        style={isFullscreen ? { paddingTop: "1.5rem" } : null}
        ref={controlRef}
        onMouseOver={showControl}
        onMouseOut={hideControl}
      >
        <IconContainer
          onClick={() => {
            captureVideos();
            html2canvas(document.body).then(function (canvas) {
              canvas.toBlob(function (blob) {
                FileSaver.saveAs(blob, "screenshot.png");
              });
            });
          }}
          role="button"
        >
          <Image src={CenterFocusStrong} />
          <Text>Screen Capture</Text>
        </IconContainer>

        <PenToolContainer>
          <IconContainer
            onClick={showPenMenu}
            className={isPenTool ? "active" : ""}
          >
            <Image src={PenTool} />
            <Text className={isPenTool ? "active" : ""}>highlighter</Text>
          </IconContainer>
          {penToolSubMenu ? (
            <PenToolSubMenu>
              <Image
                src={PenToolColor}
                onClick={() => {
                  setPenTool((prev) => !prev);
                  // console.log(!isPenTool);
                  init(!isPenTool);
                  // handleFullscreenToggle();
                }}
                className={isPenTool ? "active" : ""}
                role="button"
              />
              <Image src={Redo} onClick={undo} role="button" />
              <Image src={Undo} onClick={redo} role="button" />
              <Image src={Reset} onClick={reset} role="button" />
            </PenToolSubMenu>
          ) : null}
        </PenToolContainer>

        <IconContainer onClick={handleVideoToggle}>
          <Image src={video ? Video : VideoOff} />
          <Text>video</Text>
        </IconContainer>
        {!isSmall && (
          <IconContainer onClick={handleScreenToggle}>
            <Image className="Button1" src={screen ? Screen : ScreenOff} />
            <Text>screenshare</Text>
          </IconContainer>
        )}
        <IconContainer onClick={handleAudioToggle}>
          <Image src={audio ? Mic : MicOff} />
          <Text>mic</Text>
        </IconContainer>
        {/* <IconContainer
          className={showVolumeRange ? "active" : ""}
          style={{ position: "relative" }}
        >
          {showVolumeRange ? (
            <input
              type="range"
              min="0"
              max="100"
              value={remoteVolume * 100}
              style={{ position: "absolute", width: "2.8rem", top: "-16px" }}
              onChange={(e) => {
                setRemoteVolume(e.target.value / 100);
              }}
            />
          ) : null}
          <Image
            src={VolumeUp}
            onClick={() => setShowVolumeRange(!showVolumeRange)}
            role="button"
          />
          <Text className={showVolumeRange ? "active" : ""}>sound</Text>
        </IconContainer> */}

        <IconContainer onClick={handleCallDisconnect}>
          <Image src={End} />
          <Text>end call</Text>
        </IconContainer>
        <IconContainer onClick={handleFullscreenToggle}>
          <Image src={ScreenViewPort} />
          <Text>full screen</Text>
        </IconContainer>
      </div>
    </>
  );
};

export default Controls;

const Image = styled.img``,
  Text = styled.span`
    display: none;
    font-weight: bold;
    font-size: 9.23185px;
    line-height: 11px;
    letter-spacing: 1.06438px;
    text-transform: uppercase;
    color: #e8e8e852;
    margin-top: 18px;
    &.active {
      color: #ecf1fd;
    }
  `,
  IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    &:hover ${Text} {
      color: #ecf1fd;
    }

    :not(:first-child) {
      margin-left: 20px;
    }
    &::after {
      content: "";
      padding-bottom: 14px;
      border-bottom: 3px solid #ecf1fd;
      transition: width 300ms ease-in-out;
    }

    &:hover::after {
      width: 50%;
    }

    &.active::after {
      content: "";
      padding-bottom: 14px;
      border-bottom: 3px solid #ecf1fd;
      transition: width 300ms ease-in-out;
      width: 50%;
    }

    @media (min-width: 425px) {
      :not(:first-child) {
        margin-left: 30px;
      }
    }

    @media (min-width: 768px) {
      :not(:first-child) {
        margin-left: 40px;
      }
      ${Text} {
        display: block;
      }
    }
  `,
  PenToolContainer = styled.div`
    position: relative;
    margin-left: 20px;

    @media (min-width: 425px) {
      margin-left: 30px;
    }

    @media (min-width: 768px) {
      margin-left: 40px;
    }
  `,
  PenToolSubMenu = styled.div`
    position: absolute;
    top: -70px;
    right: -149px;
    display: flex;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 8px;

    > ${Image} {
      cursor: pointer;
      padding: 5px;
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background-color: #e7e7e7;
        border-radius: 50%;
      }
    }

    > .active {
      background-color: #dcdcdc;
      border-radius: 50%;
    }

    :after {
      content: "";
      position: absolute;
      top: 100%;
      left: 20%;
      margin-left: -10px;
      border-width: 8px;
      border-style: solid;
      border-color: white transparent transparent transparent;
    }

    @media (min-width: 500px) {
      right: -92px;
      :after {
        left: 50%;
      }
    }

    @media (min-width: 768px) {
      right: -60px;
    }
  `;
