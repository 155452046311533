import FirebaseContext from './context';
import Firebase from './firebase';
import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyDZZ-kPUtTzhDIIC7eKcLuy0hOf-qpcIiI",
  authDomain: "phyxabletest.firebaseapp.com",
  databaseURL: "https://phyxabletest.firebaseio.com",
  projectId: "phyxabletest",
  storageBucket: "phyxabletest.appspot.com",
  messagingSenderId: "257817324866",
  appId: "1:257817324866:web:c6c2bfa3e73ad472"
  };
var app = firebase.initializeApp(firebaseConfig);
var db = app.database();

export {app , db}
