import React from 'react';
import './App.css';
import VideoChat from './VideoChat';
import GlobalContextProvider from '../../Context/GlobalContextProvider';

const App = () => {
	return (
		<div className="app">
			<main>
				<GlobalContextProvider>
					<VideoChat />
				</GlobalContextProvider>
			</main>
		</div>
	);
};

export default App;
