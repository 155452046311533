import React, { useState, useCallback } from "react";
import Lobby from "./Lobby";
import Room from "./Room";
import { detect } from "detect-browser";
import OOPS from "../../Pages/OOPS.js";
import * as firebase from "../../Components/Firebase/index.js";

const browser = detect();

const VideoChat = () => {
  const [username, setUsername] = useState("");
  const [roomName, setRoomName] = useState("");
  const [token, setToken] = useState(null);

  const handleUsernameChange = useCallback((event) => {
    setUsername(event.target.value);
  }, []);

  const handleRoomNameChange = useCallback((event) => {
    setRoomName(event.target.value);
  }, []);

  // Initialize Firebase

  var writeUserData = (name, email) => {
    window.localStorage.setItem("name", name);
    firebase.db.ref("feedbacks/" + name + "Feedback").set({
      username: name,
      email: email,
    });
  };

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      // const data = await fetch(
      // `https://vid-chat-phyxable.herokuapp.com/video/token`,
      const data = await fetch(
        `https://videochat.phyxable.com/video/token`,
        {
          method: "POST",
          body: JSON.stringify({
            identity: username,
            room: roomName,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((res) => res.json());
      // .catch((error) => console.log(error));
      setToken(data.token);
      writeUserData(username, roomName);
    },
    [roomName, username]
  );

  const handleLogout = useCallback((event) => {
    setToken(null);
  }, []);

  let render;
  if (navigator.userAgent.match("FxiOS")) {
    render = <OOPS />
    return render;
  }

  if (browser.name === "crios" && browser.os === "iOS") {
    console.log(browser.name);
    console.log(browser.version);
    console.log(browser.os);
    render = <OOPS />;
    return render;
  }

  if (token) {
    render = (
      <Room roomName={roomName} token={token} handleLogout={handleLogout} />
    );
  } else {
    render = (
      <Lobby
        username={username}
        roomName={roomName}
        handleUsernameChange={handleUsernameChange}
        handleRoomNameChange={handleRoomNameChange}
        handleSubmit={handleSubmit}
      />
    );
  }
  return render;
};

export default VideoChat;
