import React from 'react';
import logo from "../Pages/VideoChat/assets/icons/logo_webbanner.png";
import error_asset from "./VideoChat/assets/icons/error_asset.svg";
const OOPS = () => {
    return (
    <center>
      <div>
      <div className="home-nav-bar">
          <div className="margin-top-8">
            <div className="center">
            <img src={logo} width="96px" ></img>
            </div>
            </div>
          </div>

          <div className = "margin-sides">
          <img className = "margin-top-80" src={error_asset} ></img>
          
          <div className="margin-top-16 oops center">Switch to Safari!</div>
      <div className="margin-top-32 futura-20-300 center">Video Calls on IOS are only supported by <b>SAFARI</b> browser.</div>
        </div>
        </div>
        </center>
        
    );
  };
  
  export default OOPS;