import React, { useState, useEffect } from "react";
import Video from "twilio-video";
import Participant from "./Participant";
import Controls from "./Controls";
import { useHistory } from "react-router-dom";
import { Howl, Howler } from "howler";
import { useGlobalPenToolContext } from "../../Context/GlobalContextProvider";
import SoundNewParticipant from "./assets/sound.mp3";
import LogoMobile from "./assets/logo.png";
import Logo from "./assets/icons/phyxable_logo.svg";
import recent_actors from "./assets/icons/recent_actors.svg";
import screenfull from 'screenfull'

const Room = ({ roomName, token, handleLogout }) => {
  let history = useHistory();
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [toggleAudio, setToggleAudio] = useState(true);
  const [toggleVideo, setToggleVideo] = useState(true);
  const [toggleScreenShare, setToggleScreenShare] = useState(false);
  const [screenStream, setScreenStream] = useState(null);
  const [isFullscreen, setFullscreen] = useState(false);

  const isPenTool = useGlobalPenToolContext();

  const sound = new Howl({
    src: [SoundNewParticipant],
    volume: 0.2
  });

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
      sound.play();
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };
    /*
    const opts = { name: roomName };
    if (screenStream) {
      
      alert('screen share')
      opts.tracks = screenStream.getTracks();
    }
    */

    Video.connect(token, {
      name: roomName,
      audio: true,
      video: {
        name: "webcam",
      },
    }).then((room) => {
      console.log(room.participants.size);
      if (room.participants.size > 1) {
        handleLogout();
        room.disconnect();
        alert("The Room is full")
        window.location.reload();
        return;
      }

      setRoom(room);
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);
    });

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  const handleCallDisconnect = () => {
    handleLogout();
    room.disconnect();
    const url = new URL(window.location.href);
    const patId = url.searchParams.get("patId");
    history.push(`/feedback?patId=${patId}`);
  };

  const handleAudioToggle = () => {
    room && room.localParticipant.audioTracks.forEach((track) => {
      console.log(track.track);
      if (track.track.isEnabled) {
        track.track.disable();
      } else {
        track.track.enable();
      }
      setToggleAudio(track.track.isEnabled);
    });
  };

  const handleVideoToggle = () => {
    room.localParticipant.videoTracks.forEach((track) => {
      if (track.track.isEnabled && track.trackName !== "screen") {
        track.track.disable();
      } else {
        track.track.enable();
      }
      setToggleVideo(track.track.isEnabled);
    });
  };

  const exitFullscreenHandler = (event) => {
    if (screenfull.isEnabled && !screenfull.element) {
      setFullscreen(false);
    }
  };

  const handleFullscreenToggle = (e, el) => {
    //
    if (!isFullscreen) {
      const element = el ? el : document.querySelector(".remote-participant")

      screenfull.isEnabled && screenfull.request(element);
      screenfull.isEnabled && screenfull.on('change', exitFullscreenHandler);
    } else {
      screenfull.isEnabled && screenfull.exit();
    }

    setFullscreen(!isFullscreen);
  };

  const handleScreenToggle = () => {
    //room.localParticipant.publishTrack(screenTrack);
    if (screenStream) {
      room.localParticipant.videoTracks.forEach((track) => {
        if (track.track.isEnabled && track.trackName === "screen") {
          track.track.stop();
          setToggleScreenShare(false);
          setScreenStream(null);
        }
      });
    } else {
      navigator.mediaDevices.getDisplayMedia().then((_screenStream) => {
        if (_screenStream) {
          const screenTrack = _screenStream.getVideoTracks()[0];

          const tracks = Array.from(room.localParticipant.videoTracks.values());

          window.room = room;
          // const tracks = Array.from(room.localParticipant.tracks.values());
          //
          // room.localParticipant.unpublishTracks(tracks);
          if (tracks.length) {
            //
            //room.localParticipant.unpublishTrack(tracks[0].track);
          }
          const pub = room.localParticipant.publishTrack(screenTrack, {
            name: "screen",
          });

          screenTrack.onended = async function () {
            (await pub).unpublish();
            setToggleScreenShare(false);
            setScreenStream(null);
          };

          setToggleScreenShare(true);

          setScreenStream(_screenStream);
        }
      });
    }
  };

  const remoteParticipants = participants.map((participant, idx) => {
    return (
      <Participant
        roomName={roomName}
        key={participant.sid}
        handleFullscreenToggle={handleFullscreenToggle}
        participant={participant}
        isLocal={false}
      />
    );
  });

  const renderControls = () => (
    <Controls
      handleCallDisconnect={handleCallDisconnect}
      handleAudioToggle={handleAudioToggle}
      handleVideoToggle={handleVideoToggle}
      handleScreenToggle={handleScreenToggle}
      handleFullscreenToggle={handleFullscreenToggle}
      isFullscreen={isFullscreen}
      audio={toggleAudio}
      video={toggleVideo}
      screen={toggleScreenShare}
    />
  );

  return (
    <div
      style={{
        background: "#2a2e35",
        height: "100%",
        padding: "10px 2% 23px",
      }}
      className={isPenTool ? "custom-cursor" : null}
    >
      <div className="navbar margin-bottom-16">
        <img className="navbar__logo-mobile" alt="Logo" src={LogoMobile} />
        <img className="navbar__logo" alt="Logo" src={Logo} />
        <div className="navbar__status">
          <div>
            Room: <span className="room__name" style={{ fontWeight: "300" }}>{roomName}</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "2.7rem",
            }}
          >
            {(remoteParticipants.length || 0) + 1}/2
            <img src={recent_actors} alt="actor" style={{ marginLeft: 10 }} />
          </div>
        </div>
      </div>
      <div className="room">
        <div
          className={`remote-participant ${
            remoteParticipants.length ? "joined" : ""
          }`}
        >
          {remoteParticipants}
          {isFullscreen ? renderControls() : null}
        </div>
        <div className="local-participant">
          {room ? (
            <Participant
              key={room.localParticipant.sid}
              participant={room.localParticipant}
              handleAudioToggle={handleAudioToggle}
              handleVideoToggle={handleVideoToggle}
              handleFullscreenToggle={handleFullscreenToggle}
              handleCallDisconnect={handleCallDisconnect}
              toggleAudio={toggleAudio}
              toggleVideo={toggleVideo}
              isLocal={true}
              isSharingScreen={toggleScreenShare}
              roomName={roomName}
            />
          ) : (
            ""
          )}
          {isFullscreen ? renderControls() : null}
        </div>
        {renderControls()}
      </div>
    </div>
  );
};

export default Room;
