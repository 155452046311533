import React, { Fragment, useState, useEffect, useRef } from "react";
import Canvas from "../../Components/Canvas/canvas";
import {
  useGlobalPenToolContext,
  useGlobalRemoteVolumeContext,
} from "../../Context/GlobalContextProvider";
import NoMic from "./assets/mic_off_show.svg";
import Dots from "./assets/dots.svg";
import NoCam from "./assets/vas_nocam.svg";

const Participant = ({
  participant,
  handleCallDisconnect,
  handleAudioToggle,
  handleFullscreenToggle,
  handleVideoToggle,
  toggleAudio,
  toggleVideo,
  isLocal,
  isSharingScreen,
  roomName,
}) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [webcamEnabled, setWebcamEnabled] = useState(true);
  const [screenShareEnabled, setScreenShare] = useState(false);
  const [showVideoMenu, setShowVideoMenu] = useState(false);
  const [isMaximized, setMaximized] = useState(false);

  const menuTimeout = useRef();

  const isPenTool = useGlobalPenToolContext();
  const remoteVolume = useGlobalRemoteVolumeContext();

  const isRemote = !isLocal;

  const videoContainerRef = useRef();
  
  const videoRef = useRef();
  const screenRef = useRef();
  const audioRef = useRef();

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap)
      .map((publication) => publication.track)
      .filter((track) => track !== null);

  useEffect(() => {
    console.log({ participant });
    // Lots of things were wrong because Twilio-Video v1 differs a lot from Twilio-Video v2
    // https://www.twilio.com/docs/video/migrating-1x-2x
    setVideoTracks(trackpubsToTracks(participant.videoTracks.values()));
    setAudioTracks(trackpubsToTracks(participant.audioTracks.values()));

    const trackSubscribed = (event) => {
      // trackPublished emits a publication as first param while trackSubscribed emits a track
      const track = event.track ? event.track : event;

      if (track.kind === "video") {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackStopped = (event) => {
      const track = event.track ? event.track : event;
      participant.unpublishTrack(track);
    };

    const trackUnsubscribed = (event) => {
      const track = event.track ? event.track : event;

      if (track.kind === "video") {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    // LocalParticipant won't subscribe to tracks, they should use the track as soon as it's published
    isLocal && participant.on("trackPublished", trackSubscribed);
    isLocal && participant.on("trackUnpublished", trackUnsubscribed);
    isLocal && participant.on("trackStopped", trackStopped);

    // RemoteParticipant can only consume a track after it has been succesfully subscribed to
    !isLocal && participant.on("trackSubscribed", trackSubscribed);
    !isLocal && participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    let shouldUnsub = [];

    const getTrackByName = (name) =>
      videoTracks.reduce(
        (acc, cur) => (acc ? acc : cur.name === name ? cur : acc),
        null
      );

    const getTrackByKind = (name) =>
      videoTracks.reduce(
        (acc, cur) => (acc ? acc : cur.kind === name ? cur : acc),
        null
      );

    const webcamTrack = getTrackByKind("video");
    const screenTrack = getTrackByName("screen");

    isRemote &&
      screenTrack &&
      screenTrack.on("enabled", (t) => {
        console.log("screenEnable");
        setScreenShare(true);
        screenTrack.attach(screenRef.current);
      });

    isRemote &&
      screenTrack &&
      screenTrack.on("disabled", (t) => {
        console.log("screenDisable");
        setScreenShare(false);
      });

    console.log(">>>> tracks:", webcamTrack, screenTrack);

    webcamTrack &&
      webcamTrack.on("enabled", (t) => {
        console.log("webcamEnable");
        setWebcamEnabled(true);
        webcamTrack.attach(videoRef.current);
      });

    webcamTrack &&
      webcamTrack.on("disabled", (t) => {
        console.log("webcamDisable");
        setWebcamEnabled(false);
      });

    const localTracks = Array.from(participant.videoTracks.values()).map(
      (v) => v.track
    );

    console.log({
      isLocal,
      isRemote,
      webcamEnabled,
      screenShareEnabled,
      isSharingScreen,
      remoteTracks: { webcamTrack, screenTrack },
      localTracks,
    });

    webcamTrack &&
      webcamTrack.isEnabled &&
      webcamTrack.attach(videoRef.current);

    // screenTrack is "stopped" so we set screenShare to off when the track is not found
    // webcamTrack is enabled/disabled based on events
    // This is because a user may want to share another Screen after stop sharing, so we always start the stream
    // from scratch again
    if (screenTrack) {
      setScreenShare(true);
      shouldUnsub.push(screenTrack);
      const curTrack = (localTracks && localTracks[1]) || screenTrack;
      curTrack.attach(screenRef.current);
    } else {
      setScreenShare(false);
    }

    return () => {
      shouldUnsub.forEach((track) => track && track.detach());
    };
  }, [videoTracks, toggleVideo, screenShareEnabled, webcamEnabled]);

  useEffect(() => {
    console.log({ audioTracks });
    const audioTrack = audioTracks[0];

    if (audioTrack) {
      audioTrack.on("enabled", (t) => {
        setAudioEnabled(true);
      });

      audioTrack.on("disabled", (t) => {
        setAudioEnabled(false);
      });

      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks, toggleAudio, audioEnabled]);

  useEffect(() => {
    if (audioRef.current && isRemote) {
      audioRef.current.volume = remoteVolume;
    }
  }, [remoteVolume]);

  const videoDiv = {
    height: "100%",
    width: "100%",
    minHeight: "200px",
    maxHeight: "100%",
    display: "flex",
    justifyContent: "flex-start",
    position: "absolute",
    bottom: "30px",
    zIndex: -1,
  };

  const videoJoinStyle = {
    objectFit: "cover",
  };

  const maximize = (el) => {
    console.log('maximizing', el)
    el.classList.remove('minimize')
    el.classList.add('maximize')
    setMaximized(true)
  }

  const minimize = (el) => {
    console.log('minimizing ', el)
    el.classList.remove('maximize')
    el.classList.add('minimize')
  }

  const disableMaximizedMode = (el) => {
    el.classList.remove('maximize', 'minimize')
    setMaximized(false)
  }

  const handleMaximize = (e) => {
    const el = e.target;
    const closestVideo = isLocal ? e.target.closest('.local-participant') : e.target.closest('.remote-participant');

    Array.from(document.querySelectorAll('.local-participant, .remote-participant')).map(curEl => {
      isMaximized ? disableMaximizedMode(curEl) : (closestVideo === curEl ? maximize(curEl) : minimize(curEl));
    })
  }

  useEffect(() => {
    const room = document.querySelector('.room')
    isMaximized ? room.classList.add('maximized') : room.classList.remove('maximized');
  }, [isMaximized])

  return (
    <Fragment>
      <div className="video-container__text" style={{ color: "white" }}>
        {participant.identity} {isLocal && "(you)"}
      </div>
      <div className="video-container" ref={videoContainerRef}>
      <div className="video__options-toggler" onClick={() => setShowVideoMenu(true)} onMouseEnter={() => setShowVideoMenu(true)} onMouseLeave={() => { menuTimeout.current = setTimeout(() => setShowVideoMenu(false), 300)}}>
	<img src={Dots} width={12} height={12} />
      </div>
      <div className={`video__options ${!showVideoMenu && `video__options--hidden`}`.trim()} onMouseEnter={() => menuTimeout.current && clearTimeout(menuTimeout.current)} onMouseLeave={() => setShowVideoMenu(false)}>
      <div className="video__options-item" onClick={(e) => {
	const closestVideo = isLocal ? e.target.closest('.local-participant') : e.target.closest('.remote-participant');
	handleFullscreenToggle(e, closestVideo);
      }}>Full screen</div>
      <div className="video__options-item" onClick={handleMaximize}>{ isMaximized ? 'Minimize' : 'Maximize' }</div>
      </div>
        <video
          className="screen"
          ref={screenRef}
          autoPlay={true}
          style={{
            display: `${
              (isRemote && screenShareEnabled) || (isLocal && isSharingScreen)
                ? "block"
                : "none"
            }`,
          }}
        />
        {isRemote && screenShareEnabled ? (
          ""
        ) : webcamEnabled ? (
          <>
            <video className={`webcam`} ref={videoRef} autoPlay={true} />
            <canvas id="canvas" hidden></canvas>
          </>
        ) : (
          <img
            src={NoCam}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        )}
        {isLocal || audioEnabled ? (
          <audio ref={audioRef} autoPlay={true} />
        ) : (
          <img
            src={NoMic}
            alt="Mic status"
            style={{
              width: "48px",
              position: "absolute",
              bottom: "16px",
              right: "10px",
            }}
          />
        )}
        {isRemote || audioEnabled ? (
          <audio ref={audioRef} autoPlay={true} />
        ) : (
          <img
            src={NoMic}
            alt="Mic status"
            style={{
              width: "48px",
              position: "absolute",
              bottom: "16px",
              right: "10px",
            }}
          />
        )}
        {isRemote ? <Canvas /> : null}
      </div>
    </Fragment>
  );
};

export default Participant;
