import React, { useRef, useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import {
  useGlobalDrawFnActionsContext,
  useGlobalUndoActionsContext,
  useGlobalRedoActionsContext,
  useGlobalPenToolContext,
  // useCurrentUserContext,
  // useRemoteSenderActionsContext,
  useGlobalResetActionsContext,
} from "../../Context/GlobalContextProvider";

// const NEW_DRAW_EVENT = "newDrawEvent"; // Name of the event
// const SOCKET_SERVER_URL = `http://${window.location.hostname}:5000`;
// const SOCKET_SERVER_URL = `https://vid-chat-phyxable.herokuapp.com/`;

let plots = [];
// let redo_list = [];
// let undo_list = [];
let undoIndex = -1;
let undoStack = [];

function App({ status }) {
  // const [messages, setMessages] = useState([]);
  // const [clearRemoteDraw, SetClearRemoteDraw] = useState(false);
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  // const socketRef = useRef();
  const setUndo = useGlobalUndoActionsContext();
  const setRedo = useGlobalRedoActionsContext();
  const setReset = useGlobalResetActionsContext();
  const setEnableDrawFn = useGlobalDrawFnActionsContext()
  const isPenTool = useGlobalPenToolContext();
  // const currentUser = useCurrentUserContext();
  // const setRemoteSender = useRemoteSenderActionsContext();
  let isDrawing = false;

  //console.log({ plots, undo_list, redo_list })
  const initializeCanvas = () => {
    const videoContainer = document.querySelector(".video-container");
    const canvas = canvasRef.current;
    canvas.width = videoContainer.getBoundingClientRect().width;
    canvas.height = videoContainer.getBoundingClientRect().height;
    canvas.style.width = `${videoContainer.getBoundingClientRect().width}px`;
    canvas.style.height = `${videoContainer.getBoundingClientRect().height}px`;

    const context = canvas.getContext("2d");
    context.lineCap = "round";
    context.strokeStyle = "#FF5A66";
    context.lineWidth = 3;
    contextRef.current = context;
    console.log({ canvas })
  }

  useEffect(() => {
    console.log('Re-rendering canvas')
    initializeCanvas()
    saveState(canvasRef.current);
    const videoContainer = document.querySelector(".video-container");

    setEnableDrawFn({ init: penToolInit });
    // penToolInit();
    setUndo({ undo });
    setRedo({ redo });
    setReset({ reset });

    const resizeObserver = new ResizeObserver(entries => {
      initializeCanvas();
      console.log({entries, newWidth: entries[0].contentRect.width })
    })

    resizeObserver.observe(videoContainer);
    
    return () => {
      resizeObserver.unobserve(videoContainer);
    }
  }, []);

  function saveState(canvas) {
    // keep_redo = keep_redo || false;
    // if (!keep_redo) {
    //   redo_list = [];
    // }

    ++undoIndex;
    if (undoIndex < undoStack.length) {
      // any new action flushes everything we undid
      undoStack.length = undoIndex;
    }
    undoStack.push(canvas.toDataURL());
    console.log('saveState', undoIndex, undoStack);
  }

  function undo() {
    // debugger;
    restoreState(-1);
    // remoteCall(plots, undo_list, redo_list);
  }

  function redo() {
    restoreState(1);
    // remoteCall(plots, redo_list, undo_list);
  }

  function canUndo(delta) {
    return undoIndex + delta >= 0 && undoIndex + delta < undoStack.length;
  }

  function restoreState(delta) {
    // console.log('restartState', { pop, push })
    if (!canUndo(delta)) {
      // nothing to do
      return;
    }
    

    undoIndex += delta;
    var restore_state = undoStack[undoIndex];
    console.log('restoreState', delta, undoIndex, undoStack);
    // = pop.pop();
    // var img = new Element('img', { src: restore_state });
    var img = new Image();
    img.src = restore_state;
    img.onload = function () {
      contextRef.current.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      contextRef.current.drawImage(
        img,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
    };
  }

  function penToolInit(drawState) {
    isDrawing = false;

    if(drawState) {
      addCanvasEvents();
    } else {
      removeCanvasEvents()
    }
  }

  function addCanvasEvents() {
    canvasRef.current.addEventListener("mousedown", startDrawing);
    canvasRef.current.addEventListener("mousemove", draw);
    canvasRef.current.addEventListener("mouseup", finishDrawing);
    //canvasRef.current.addEventListener("mouseout", finishDrawing);
  }
 
  function removeCanvasEvents() {
    canvasRef.current.removeEventListener("mousedown", startDrawing);
    canvasRef.current.removeEventListener("mousemove", draw);
    canvasRef.current.removeEventListener("mouseup", finishDrawing);
    //canvasRef.current.removeEventListener("mouseout", finishDrawing);
  }

  const draw = ({ offsetX, offsetY }) => {
    if (!isDrawing) {
      return;
    }
    var x = offsetX;
    var y = offsetY;
    plots.push({ x, y });
    drawOnCanvas(plots);
  };

  const drawOnCanvas = (plots, remote) => {
    contextRef.current.beginPath();
    contextRef.current.moveTo(plots[0].x, plots[0].y);
    for (var i = 1; i < plots.length; i++) {
      contextRef.current.lineTo(plots[i].x, plots[i].y);
    }
    contextRef.current.stroke();
    isDrawing = true;
    // if (!remote) remoteCall(plots);
  };

  const startDrawing = ({ offsetX, offsetY, layerX, layerY }) => {
    isDrawing = true;
  };

  const finishDrawing = () => {
    console.log('finishDrawing')
    contextRef.current.closePath();
    saveState(canvasRef.current);
    plots = [];
    isDrawing = false;
  };

  const reset = () => {
    contextRef.current.clearRect(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );

    // reset undoStack
    undoIndex = 0;
    undoStack.length = 1;
  };

  return <canvas ref={canvasRef} className="canvas" />;
}

export default App;
