import React, { createContext, useState, useContext } from "react";

const GlobalPenToolContext = createContext();
const GlobalPenToolActionsContext = createContext();
const GlobalDrawFnContext = createContext();
const GlobalDrawFnActionsContext = createContext();
const GlobalUndoContext = createContext();
const GlobalUndoActionsContext = createContext();
const GlobalRedoContext = createContext();
const GlobalRedoActionsContext = createContext();
const GlobalRemoteVolumeContext = createContext();
const GlobalRemoteVolumeActionsContext = createContext();
const GlobalResetContext = createContext();
const GlobalResetActionsContext = createContext();

const factoryUseContext = (name, context) => {
  return () => {
    const ctx = useContext(context);
    if (ctx === undefined) {
      throw new Error(
        `use${name}Context must be used withing a ${name}ContextProvider.`
      );
    }
    return ctx;
  };
};

export const useGlobalPenToolContext = factoryUseContext(
  "GlobalPenTool",
  GlobalPenToolContext
);

export const useGlobalPenToolActionsContext = factoryUseContext(
  "GlobalPenToolActions",
  GlobalPenToolActionsContext
);

export const useGlobalDrawFnContext = factoryUseContext(
  "GlobalDrawFn",
  GlobalDrawFnContext
);

export const useGlobalDrawFnActionsContext = factoryUseContext(
  "GlobalDrawFnActions",
  GlobalDrawFnActionsContext
);

export const useGlobalUndoContext = factoryUseContext(
  "GlobalUndo",
  GlobalUndoContext
);

export const useGlobalUndoActionsContext = factoryUseContext(
  "GlobalUndoActions",
  GlobalUndoActionsContext
);

export const useGlobalRedoContext = factoryUseContext(
  "GlobalRedo",
  GlobalRedoContext
);

export const useGlobalRedoActionsContext = factoryUseContext(
  "GlobalRedoActions",
  GlobalRedoActionsContext
);

export const useGlobalRemoteVolumeContext = factoryUseContext(
  "GlobalRemoteVolume",
  GlobalRemoteVolumeContext
);

export const useGlobalRemoteVolumeActionsContext = factoryUseContext(
  "GlobalRemoteVolumeActions",
  GlobalRemoteVolumeActionsContext
);


export const useGlobalResetContext = factoryUseContext(
  "GlobalReset",
  GlobalResetContext
);

export const useGlobalResetActionsContext = factoryUseContext(
  "GlobalResetActions",
  GlobalResetActionsContext
);

const GlobalContextProvider = (props) => {
  const [isPenTool, setPenTool] = useState(false);
  const [enableDrawFn, setEnableDrawFn] = useState({});
  const [undo, setUndo] = useState({});
  const [redo, setRedo] = useState({});
  const [remoteVolume, setRemoteVolume] = useState(1);
  const [reset, setReset] = useState({});

  return (
    <GlobalResetContext.Provider value={reset}>
      <GlobalResetActionsContext.Provider value={setReset}>
        <GlobalRemoteVolumeContext.Provider value={remoteVolume}>
          <GlobalRemoteVolumeActionsContext.Provider value={setRemoteVolume}>
            <GlobalDrawFnContext.Provider value={enableDrawFn}>
              <GlobalDrawFnActionsContext.Provider value={setEnableDrawFn}>
                <GlobalPenToolContext.Provider value={isPenTool}>
                  <GlobalPenToolActionsContext.Provider value={setPenTool}>
                    <GlobalUndoContext.Provider value={undo}>
                      <GlobalUndoActionsContext.Provider value={setUndo}>
                        <GlobalRedoContext.Provider value={redo}>
                          <GlobalRedoActionsContext.Provider value={setRedo}>
                            {props.children}
                          </GlobalRedoActionsContext.Provider>
                        </GlobalRedoContext.Provider>
                      </GlobalUndoActionsContext.Provider>
                    </GlobalUndoContext.Provider>
                  </GlobalPenToolActionsContext.Provider>
                </GlobalPenToolContext.Provider>
              </GlobalDrawFnActionsContext.Provider>
            </GlobalDrawFnContext.Provider>
          </GlobalRemoteVolumeActionsContext.Provider>
        </GlobalRemoteVolumeContext.Provider>
      </GlobalResetActionsContext.Provider>
    </GlobalResetContext.Provider>
  );
};

export default GlobalContextProvider;
