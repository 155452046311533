import React, { Component } from "react";
import "./styles.css";
import "../VideoChat/App.css";
import logo from "../VideoChat/assets/icons/logo_webbanner.png";
import VAS_1 from "../VideoChat/assets/vas/VAS_FEEDBACK_1.svg";
import VAS_3 from "../VideoChat/assets/vas/VAS_FEEDBACK_3.svg";
import VAS_5 from "../VideoChat/assets/vas/VAS_FEEDBACK_5.svg";
import VAS_1_CLICKED from "../VideoChat/assets/vas/VAS_FEEDBACK_1_CLICKED.svg";
import VAS_3_CLICKED from "../VideoChat/assets/vas/VAS_FEEDBACK_3_CLICKED.svg";
import VAS_5_CLICKED from "../VideoChat/assets/vas/VAS_FEEDBACK_5_CLICKED.svg";
import * as firebase from '../../Components/Firebase/index.js'

class FeedbackPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      VAS: 'Great',
      textareaValue: ''
    }
  }

  // writeData = (path, key, value) => {
  //   console.log("write data", path, key, value);
  //   firebase
  //     .ref(`${path}`)
  //     .update({
  //       [key]: value
  //     })
  //     .then(() => {
  //       console.log("write data proim");
  //     });
  // };

  generateRandomKey = () => {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  };

  handleOnChange(event) {
    console.log(event.target.value)
    this.setState({
      textareaValue: event.target.value
    }, ()=> console.log(this.state.textareavalue))
    
  }

  onSubmit = async () => {
    var name = await window.localStorage.getItem('name')
    firebase.db.ref('feedbacks/' + name+'Feedback').update({
      feedback: this.state.textareaValue,
      VAS: this.state.VAS
    });
  
 
    window.location.href = "/";
  };

  render() {

      return ( 
      <div>
      <div className="home-nav-bar">
        <div className="margin-top-8">
          <div className="center">
          <img src={logo} width="96px" ></img>
          </div>
          </div>
        </div>

        <div className="margin-top-64 center">
        <div className="futura-20-900">Share Your Feedback</div>
        <div className="futura-16-300 margin-top-8">How did your 1-on-1 session go with Phyxable?</div>
        <div className="margin-top-8 futura-16-300 bold" style={ this.state.VAS === "OK" ? {color: "#F4A856", fontSize : "24px"} : this.state.VAS === "Bad" ? {color: "#FF5A66", fontSize: "24px"} : {color: "#74D173", fontSize: "24px"} }>{this.state.VAS}</div>
        <div className="row margin-top-8">
          <div className="column font-size">
            <img className="img" onClick={() => { this.setState({ VAS: "Bad" }) }} src={ this.state.VAS === "Bad" ? VAS_1_CLICKED : VAS_1 }></img>
          </div>
          <div className="column">
            <img className="img" onClick={() => { this.setState({ VAS: "OK" }) }} src={ this.state.VAS === "OK" ? VAS_3_CLICKED : VAS_3 }></img>
          </div>
          <div className="column font-size">
            <img className="img" onClick={() => { this.setState({ VAS: "Great" }) }} src={ this.state.VAS === "Great" ? VAS_5_CLICKED : VAS_5 }></img>
          </div>
        </div>

            <div className="margin-top-32 center">
            <textarea className="textarea" placeholder="Any comments?" value={this.state.textareaValue} onChange={(event) => this.handleOnChange(event)} ></textarea>
            </div>
            <div className="onboarding-bottom-nav-bar">
              <button className="green-button" disabled={this.state.VAS === null} 
              style={ this.state.VAS === null ? {opacity: "0.25"} : null }
              onClick={()=>{this.onSubmit()}}>SEND</button>
            </div> 
        </div>
      </div>)
  }
}

export default FeedbackPage;