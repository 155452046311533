import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import * as routes from "../../Constants/routes";
import "./styles.css";
import VideoChat from "../../Pages/VideoChat/App.js";
import FeedbackPage from "../../Pages/FeedbackPage/index.jsx";

class App extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
        <Router>
            <Route
              exact
              path={routes.VIDEO_CHAT}
              render={props => (
                <VideoChat/>
              )}
            />
            <Route
              exact
              path={routes.FEEDBACK}
              render={props => (
                <FeedbackPage/>
              )}
            />
        </Router>
    );
  }
}
export default App;
